define("labs-zap-search/templates/components/info-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w+XTVMZi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"open\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"reveal-overlay\"],[10,\"style\",\"display:block; background-color: rgba(241, 242, 244, 0.45)\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"reveal-overlay-target\"],[3,\"action\",[[23,0,[]],[24,[\"closeModal\"]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"reveal info-modal\"],[10,\"role\",\"dialog\"],[10,\"aria-hidden\",\"false\"],[10,\"tabindex\",\"-1\"],[10,\"style\",\"display:block;\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[0,\"Subscribe and Get Notified.\"],[9],[0,\"\\n\\n      \"],[7,\"h4\",true],[8],[0,\"Subscribe to \"],[5,\"link-to\",[[3,\"action\",[[23,0,[]],[24,[\"closeModal\"]]]]],[[\"@route\"],[\"subscribe\"]],{\"statements\":[[0,\"ZAP Email notifications\"]],\"parameters\":[]}],[0,\". Get updates about community district and citywide projects.\"],[9],[0,\"\\n\\n      \"],[1,[28,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,0,[\"dontShowModalAgain\"]]]]],false],[0,\"\\n      Don't show this message again\\n      \"],[7,\"button\",false],[12,\"class\",\"close-button\"],[12,\"aria-label\",\"Close modal\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"closeModal\"]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/info-modal.hbs"
    }
  });

  _exports.default = _default;
});